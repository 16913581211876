import React, { useEffect, useState } from "react"
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  PlanBnr,
  Access,
  ImgList,
  InfoTable,
  FacilityPostBtns,
  FacilityDNav,
  KitchenList,
  AmenitieList,
  FaqList,
  wovnCheck,
  Accodion,
} from '../../../components/_index';


// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  const [floorPdf, setFloorPdf] = useState('floorPlan_takeyamachi.pdf')
  wovnCheck(function (wovnCode: string) {
    const pdffile = wovnCode !== 'ja' ? 'floorPlan_takeyamachi_en.pdf' : 'floorPlan_takeyamachi.pdf';
    setFloorPdf(pdffile)
  });
  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <main id="facility_d" className="nishijin-villa under">
        <div id="facility_dMv">
          <div className="mvCont">
            <p className="ico">#8</p>
            <h2 className="facility_dMvTit">京の温所 竹屋町<span>TAKEYAMACHI</span></h2>
            <div className="mvImg" style={{ backgroundImage: 'url(/images/facility/7416cb9615dfc1f059a0aadb5dad1bc4.jpg)' }}></div>
          </div>
          <FacilityDNav data={[
            {
              anchor: "#facility_dInfo",
              label: <>施設情報</>,
            },
            {
              anchor: "#facility_dAmenitie",
              label: <>アメニティ</>,
            },
            {
              anchor: "#facility_dAccess",
              label: <>アクセス</>,
            },
            {
              anchor: "#facility_dPlan",
              label: <>プラン一覧</>,
            },
            {
              anchor: "#facility_dFaq",
              label: <>よくあるご質問</>,
            },
          ]} />
        </div>
        <div id="facility_dWrap" className="wd">
          <div id="facility_dInfoWrap">
            <section id="facility_dInfoAbout">
              <h2 className="tit">京町家の原点である「職住一体」の<br className="sp" />暮らしを体験できる宿</h2>
              <p>「京の温所 竹屋町」は京都御所の南西に位置し、地下鉄丸太町駅から徒歩6分。普段使いのごはん屋やカフェ、漬物屋、銭湯などが住宅の間に立ち並ぶ、「ふだんの京都」を感じる静かな通りに面しています。<br />長年道具屋としての「商い」と、生活する場である「住まい」がひとつの建物の中で営まれてきた京町家は、築130年以上。「職住一体」の特徴的な間取りを生かし、建てられた当時の風情をできるだけ残しながら、今の暮らしに寄り添うようにリノベーションしました。<br />「商い」はベーカリー＆カフェと変化を遂げた「京の温所 竹屋町」では、生地を捏ねるリズミカルな音や、香ばしいパンの香りがしてきたら目覚める合図。ダイニングから見える蔵の中でつくられたパンがいただける、朝の時間が愛おしくなる宿です。</p>
              <p>
                <br />
                <Link to="/news/44szy2_152/"><strong><u>館内には、「ダブディビ・デザイン」がセレクトした障害のある方のアート作品を展示しています。</u></strong></Link>
              </p>
              <FacilityPostBtns data={[
                {
                  href: `/assets/files/facility/${floorPdf}`,
                  blank: true,
                  label: <>間取り図<small>（PDF）</small></>,
                },
                {
                  href: "/news/recommend/#takeyamachi",
                  blank: false,
                  label: <>周辺のおすすめ</>,
                },
              ]} />
            </section>
            <section id="facility_dInfo">
              <h2 className="tit">INFORMATION</h2>
              <p className="post">京の温所 竹屋町 #8<br /><a href="https://goo.gl/maps/1vVPYv2SzTxrZWcn9" target="_blank">京都市中京区竹屋町通西洞院東入指物屋町371</a>
              </p>
              <InfoTable data={[
                {
                  label: <>広さ</>,
                  value: <>2階建て88平米（1棟貸切）</>,
                },
                {
                  label: <>定員</>,
                  value: <>1～4名</>,
                },
                {
                  label: <>部屋</>,
                  value: <>&lt;1階&gt; ライブラリー ベッドルーム 坪庭 風呂（ひのき）洗面 トイレ<br />
                    &lt;2階&gt; ダイニング キッチン 和室 トイレ</>,
                },
                {
                  label: <>寝具</>,
                  value: <>セミダブルベッド2台　ふとん2組（3名よりご用意）</>,
                },
                {
                  label: <>設備</>,
                  value: <>エアコン、空気清浄機、洗濯機、床暖房、浄水器（クリンスイ）</>,
                },
                {
                  label: <>テレビ</>,
                  value: <>あり（19インチ ポータブルタイプ）<br />※インターネット、機器等との接続不可</>,
                },
                {
                  label: <>スピーカー</>,
                  value: <>Bluetoothスピーカー</>,
                },
                {
                  label: <>Wi-Fi</>,
                  value: <>無料Wi-Fi（無線LAN）</>,
                },
                {
                  label: <>禁煙</>,
                  value: <>建物・敷地内　全面禁煙</>,
                },
              ]} />
            </section>
          </div>
          <div id="facility_dGallery" className="facility_dSection">
            <ImgList data={[
              '/images/facility/6e4a87d2dec87191184540a9bfeccee0_1.jpg',
              '/images/facility/a3cfc15ad34580cff96b18b05424c477_1.jpg',
              '/images/facility/bcc15d78be361ddcb70a26eb2325f95a_1.jpg',
              '/images/facility/2050f38313f352eefb168f035541d611_1.jpg',
              '/images/facility/b83877795ce1e7c5de56d5788970c2cf_1.jpg',
              '/images/facility/f07e30ac189a979175af2523b72bec37_1.jpg',
              '/images/facility/ac9009ccd5a7d169520f56ecfc3d2d6c_1.jpg',
              '/images/facility/c013e097b9f173fefab657d79c518037_1.jpg',
              '/images/facility/20557373e662602436bff2dbca4623af.jpg',
            ]} />
          </div>
          <section id="facility_dIntro" className="facility_d facility_dSection">
            <h2 className="facility_dTit">「商い」と「住まい」が共に営まれてきた京町家の原点</h2>
            <p>「京の温所 竹屋町」は、道具屋としての「商い」と、生活する場である「住まい」がひとつの建物の中で営まれてきた明治初期に建てられた京町家です。京町家の原点ともいわれる「職住一体」の特徴的な間取りをそのまま生かし、表通りから裏の蔵まで続く通り土間は、ベーカリー＆カフェとして生まれ変わり「ふだんの京都」に溶け込むような近隣地域とのゆるやかな繋がりがうまれました。<br />表の喧騒から一歩宿に入ると、玄関からすぐに見えてくるのが京町家特有の坪庭。1階は坪庭を中心に、ひのき風呂、ベッドルーム、ライブラリーがあり、明治初期に建てられた当時の風情を感じながらくつろいでいただけます。
            </p>
            <ImgList data={[
              '/images/facility/048c0dfa05fef9927fcc47ba30244ef6.jpg',
              '/images/facility/4f611bfeb03c367de55b561bb492ed1d.jpg',
              '/images/facility/1ea3bb7b6dde2d1fa5f01c5ff19a7d66.jpg',
            ]} />
          </section>
          <section id="facility_dKitchen" className="facility_d facility_dSection">
            <div>
              <h2 className="facility_dTit">「商い」の息づかいを感じるキッチン＆ダイニング</h2>
              <p>階段を上がり、2階にあるキッチン＆ダイニングの大きな窓から見えるのは、ベーカリー「パンとエスプレッソと本日の」のパンがつくられている大きな蔵の白い壁。窓を開けると心地よい風と共に、生地を捏ねるリズミカルな音が聞こえてきたり、香ばしいパンが焼ける香りがただよってきて、「商い」の息づかいを感じます。<br />キッチンには簡単なものから本格的なものまで、パンに合う料理を作って楽しんでいただけるように、美味しいトーストが焼けるスチームオーブントースターをはじめ、冷凍冷蔵庫、IHの三口コンロ、グリルにオーブンレンジ、また用と美を兼ね備えた調理器具や、食器、カトラリーもご用意しています。
              </p>
            </div>
            <ImgList data={[
              '/images/facility/2d211e29d4841e10b1b99bb97a39325f.jpg',
              '/images/facility/cccf7d61a5503de677509e0f99cca825.jpg',
              '/images/facility/1d58fda994d45dbba11443be0f9c6b26.jpg',
            ]} />
            <Accodion data={{
              title: <>キッチン備品</>,
              contents: <div className="facility_dOtherList">
                <dl>
                  <dt>設備</dt>
                  <dd>
                    <ul>
                      <li>冷凍冷蔵庫</li>
                      <li>オーブンレンジ</li>
                      <li>浄水器</li>
                      <li>トースター</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dt>鍋類</dt>
                  <dd>
                    <ul>
                      <li>鍋（ストウブ）</li>
                      <li>雪平鍋</li>
                      <li>フライパン</li>
                      <li>炊飯鍋</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dt>ツール</dt>
                  <dd>
                    <ul>
                      <li>包丁</li>
                      <li>ペティナイフ</li>
                      <li>まな板</li>
                      <li>ザル</li>
                      <li>木べら</li>
                      <li>お玉</li>
                      <li>トング</li>
                      <li>フライ返し</li>
                      <li>ピーラー</li>
                      <li>ワインオープナー</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dt>食器類</dt>
                  <dd>
                    <ul>
                      <li>皿</li>
                      <li>小鉢</li>
                      <li>マグカップ</li>
                      <li>グラス類</li>
                      <li>急須・湯呑み</li>
                      <li>箸・スプーン・フォーク・ナイフ</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dt>その他</dt>
                  <dd>
                    <ul>
                      <li>ペーパータオル</li>
                      <li>食器洗い用洗剤</li>
                      <li>スポンジ</li>
                    </ul>
                  </dd>
                </dl>
                <p><small>※ 調味料類のご用意はございません。</small></p>
              </div>
            }} />
          </section>
          <section id="facility_dFeature" className="facility_d facility_dSection">
            <h2 className="facility_dTit">手仕事のぬくもりを感じる家具</h2>
            <p>テーブルやイス、ソファは京都・西陣にショールームを構える「片井意匠」による手仕事のぬくもりを感じる家具を取り揃えました。さらにライブラリーは、工芸やパンなどをテーマに書店「誠光社」の堀部 篤史氏がセレクト。京都ゆかりの方々と空間をつくり上げました。</p>
            <ImgList data={[
              '/images/facility/b44cfe10f57b95fb2edff67b0a597d58.jpg',
              '/images/facility/bc51e0f91615eba8fa892c4631612929.jpg',
              '/images/facility/86adeb8a3feaef85841c6930ed5d3368.jpg',
            ]} />
          </section>
          <AmenitieList data={[
            '/images/facility/amenities_bath.jpg',
            '/images/facility/20221031sentaku.jpg',
            '/images/facility/amenities_pajamas.jpg',
          ]} />
          <section id="facility_dAccess">
            <Access data={{
              train: <>地下鉄烏丸線「丸太町」駅6番出口　徒歩約6分</>,
              bus: <>「府庁前」下車　徒歩約2分<br />「堀川丸太町」下車　徒歩約6分</>,
              taxi: "",
              parking: <>駐車場はございません。（近隣パーキング：徒歩2分圏内数か所あり）</>,
              img: "",
              maplink: "https://goo.gl/maps/1vVPYv2SzTxrZWcn9",
              map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13070.47056630741!2d135.7560956!3d35.0162754!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x974220405b2f970d!2z5Lqs44Gu5rip5omAIOerueWxi-eUuiAjOA!5e0!3m2!1sja!2sjp!4v1596698486217!5m2!1sja!2sjp",
            }} />
          </section>
          <section id="facility_dPlan">
            <PlanBnr roomcd="0000001672" />
          </section>
          <FaqList />
        </div>
      </main>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
